import styled from "styled-components";

export const Header = styled.div`
   
    display: flex;
    width: 100%;
    z-index: 9;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    font-size: .75rem;
    color: #AD3EDB;
    img {
        height: 20px;
    }
    p {
        padding-left: 24px;
    }
`
