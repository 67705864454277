import React, {forwardRef} from "react";
import DrawingLine from "../drawingLine";
import {SVG} from './style'

const Drawing = forwardRef(({ lines }, ref) => {
    return (
        <SVG ref={ref} xmlns="http://www.w3.org/2000/svg">
            {lines.map((line, index) => <DrawingLine key={index} line={line} />)}
        </SVG>
    );
})

export default Drawing