import styled, {createGlobalStyle} from 'styled-components'
import ProximaLight from '../../assets/fonts/ProximaNovaLight.otf'
import ProximaSemi from '../../assets/fonts/ProximaNovaSemibold.otf'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Proxima";
        src: url('${ProximaLight}') format('opentype');
    }
    
    @font-face {
        font-family: "Proxima";
        font-weight: bold;
        src: url('${ProximaSemi}') format('opentype');
    }

    *, *:after, *:before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body, #root {
        overflow: hidden;
        font-family: "Proxima",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
        font-size: 100%;
        width: 100%;
        height: 100%;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`