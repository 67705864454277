import React, {useEffect, useState, useRef} from "react";
import Immutable from 'immutable'
import Drawing from "../drawing";
import Dialog from "../dialog"
import moment from 'moment'

import {Area, Container, Buttons} from './style'
import {useWindowSize} from '../../hooks/windowSize'

const DrawArea = () => {
    const [lines, setLines] = useState(new Immutable.List())
    const [isDrawing, setIsDrawing] = useState(false)
    const [dialogTerms, setDialogTerms] = useState(true)
    const [dataGeo, setDataGeo] = useState(true)
    const [terms, setTerms] = useState(false)
    const size = useWindowSize();
    //size.height > size.width
    const drawAreaEl = useRef();
    const signatureEl = useRef();
    const isTouchDevice = 'ontouchstart' in document.documentElement

    useEffect(() => {
        console.log('xxx')
        fetch('http://ip-api.com/json').then((res) => res.json().then(data => setDataGeo({
            ...data, text: `Local:  ${data.city} - ${data.country} em ${moment().format('DD/MM/YYYY HH:mm:ss')}
 (Latidute: ${data.lat}, Longitude: ${data.lon}) 
Rede: ${data.query} - ${data.org}`
        }))).catch(err => console.log(err))
        if (isTouchDevice) {
            document.addEventListener("touchend", (e) => handleMouseUp(e));
            return () => {
                document.removeEventListener("touchend", (e) => handleMouseUp(e));
            }
        } else {
            document.addEventListener("mouseup", (e) => handleMouseUp(e));
            return () => {
                document.removeEventListener("mouseup", (e) => handleMouseUp(e));
            }
        }
    }, [])

    function relativeCoordinatesForEvent(mouseEvent) {
        const boundingRect = drawAreaEl.current.getBoundingClientRect();
        return new Immutable.Map({
            x: (isTouchDevice ? mouseEvent.touches[0].clientX : mouseEvent.clientX) - boundingRect.left,
            y: (isTouchDevice ? mouseEvent.touches[0].clientY : mouseEvent.clientY) - boundingRect.top,
        });
    }

    function handleMouseMove(mouseEvent) {
        if (!isDrawing) {
            return;
        }

        const point = relativeCoordinatesForEvent(mouseEvent);

        setLines(prevState => prevState.updateIn([prevState.size - 1], line => line.push(point)))
    }

    function svgToPng(svg, callback) {
        let a = document.createElement('a')
        a.href = `https://api.whatsapp.com/send?phone=5511966508248&text=Assinado%20em%20${moment().format('DD/MM/YYYY HH:mm:ss')}%20token:%20${moment().format('DDMMYYYYHHmmss')}${dataGeo.query.replaceAll('.','')}`
        a.target = '_parent'
        a.click()
        /*const string = new XMLSerializer().serializeToString(document.querySelector('svg'))
        const blob = new Blob([string], {type: "image/svg+xml;charset=utf-8"})
        const img = new Image()
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext("2d");
        canvas.width = svg.clientWidth;
        canvas.height = svg.clientHeight;
        const svgURL = URL.createObjectURL(blob)
        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            const png = canvas.toDataURL("image/png")
            console.log(png)

        }
        img.src = svgURL*/
    }

    function handleMouseUp() {
        setIsDrawing(false)
    }

    function handleMouseDown(mouseEvent) {
        if (!isTouchDevice) {
            if (mouseEvent.button !== 0) {
                return;
            }
        }
        const point = relativeCoordinatesForEvent(mouseEvent);

        setLines(prevState => prevState.push(new Immutable.List([point])))
        setIsDrawing(true)
    }

    function send(content) {

    }

    console.log(dataGeo)
    return <Container>
        <Area ref={drawAreaEl} onTouchStart={(e) => isTouchDevice && handleMouseDown(e)}
              onTouchMove={(e) => isTouchDevice && handleMouseMove(e)}
              onMouseDown={(e) => !isTouchDevice && handleMouseDown(e)}
              onMouseMove={(e) => !isTouchDevice && handleMouseMove(e)}>
            {lines.size > 0 ? <Drawing ref={signatureEl} lines={lines}/> :
                <div>
                    <p>Por favor assine igual a sua CNH ou RG</p>
                    {size.height > size.width && <p>(Se possível vire o celular na horizontal)</p>}
                </div>}
        </Area>
        {lines.size > 0 && <Buttons>
            <button onClick={() => setLines(new Immutable.List())}>Resetar</button>
            <button onClick={() => svgToPng(signatureEl.current, send)}>Enviar</button>
        </Buttons>}
        <Dialog isOpen={dialogTerms} title={"Termos e condições"}>
            Aqui irá aparecer os termos do contrato parametrizado em sistema e que deverão ser lidos e aceitos pelo
            consumidor para habilitar o botão de assinatura
            <div className={'accept'}><input type="checkbox" value={terms} id="terms"
                                             onChange={({target}) => setTerms(target.value === 'false')}/><label
                htmlFor="terms">Aceito os termos e condições</label></div>
            <button disabled={!terms} onClick={() => setDialogTerms(false)}>Assinar</button>
            {terms && <>
                <p style={{marginTop: 10}}>{`Local:  ${dataGeo.city} - ${dataGeo.country} em ${moment().format('DD/MM/YYYY HH:mm:ss')}`}</p>
                <p>{`Latidute: ${dataGeo.lat}, Longitude: ${dataGeo.lon})`}</p>
                <p>{`${dataGeo.query} - ${dataGeo.org}`}</p>
            </>}
        </Dialog>
    </Container>
}
export default DrawArea