import React from 'react';
import DrawArea from "../drawArea";
import Header from "../header";
import {Normalize} from 'styled-normalize'
import {GlobalStyle,Container} from './style'
function App() {
    return <Container>
        <GlobalStyle/>
        <Normalize/>
        <Header/>
        <DrawArea/>
    </Container>
}

export default App;