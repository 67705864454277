import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    height: calc(100% - 60px);
    >p {
        user-select: none;
    }
`
export const Buttons = styled.div`
    position: absolute;
    bottom: 12px;
    right: 24px;
    user-select: none;
    > button {
        padding: 8px 14px;
        border: 0;
        color: #fff;
        background-color: #686868;
        margin-left: 9px;
        & + button {
            background-color: #AD3EDB;
        }
    }
`
export const Area = styled.div`
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
`
