import React from "react";
import {Header as Container} from './style'
import Logo from '../../assets/images/logo.png'

const Header = () => {
    return <Container>
        <img src={Logo} alt="Logo digitalk"/>
        <p>Ambiente de demonstração Digitalk para coleta e validação de assinatura digital.</p>
    </Container>
}

export default Header