import styled, {css, keyframes} from 'styled-components'

const show = keyframes`
    from {opacity: 0;}
    to {opacity: 1}
`

export const Container = styled.div `
    display:flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.3);
    justify-content: center;
    align-items: center;
    z-index: 999;

    ${({isOpen}) => isOpen && css`
        animation: ${show} .3s ease-out;
    `}
`
export const DialogBox = styled.div ` 
    padding: 32px;
    background-color: #FFF;
    border-radius: 3px;
    width: ${({width}) => width ?? 'auto'};
    height: ${({height}) => height ?? 'auto'};
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 80%;
    .header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        span {
            align-self: flex-end;
            margin-left: 150px;
        }
    }
    .container {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        .accept {
            margin-top: 8px;
            align-self: flex-end;
            label {
                margin-left: 6px;
            }
        }
        button {
            margin-top: 12px;
            padding: 10px 0;
            background-color: transparent;
        }
    }
`

export const Close = styled.span`
    float: right;
    font-size: 28px;
    font-weight: bold;

    :hover, :focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`