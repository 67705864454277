import React from 'react'
import PropTypes from 'prop-types'
import {Container, DialogBox, Close} from './styled'

const Dialog = props => {
    const {isOpen, children, handleClose, title} = props

    return isOpen && <Container isOpen={isOpen}>
        <DialogBox {...props}>
            <div className='header'><h3>{title}</h3>{handleClose && <Close onClick={() => handleClose(false)}>&times;</Close>}</div>
            <div className="container">{children}</div>
        </DialogBox>
    </Container>
}

Dialog.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
    handleClose: PropTypes.any,
}

Dialog.defaultProps = {
    title: ''
}

export default Dialog